<template>
  <v-container fluid text-xs-center>
    <v-layout row wrap style="margin: 0px">
      <v-flex xs12 style="padding: 5px">
        <v-card text-xs-center elevation="0">
          <v-layout row wrap style="padding: 4%">
            <v-flex xs12 md12 sm12 d-flex justify-center>
              <v-avatar size="90">
                <img :src="computeLogo" alt="GoDial" />
              </v-avatar>
            </v-flex>

            <v-flex xs12 md12 sm12 d-flex justify-center style="padding: 2%"
              >GoDial Enterprise v28</v-flex
            >
          </v-layout>

          <v-divider></v-divider>

          <v-layout row wrap>
            <v-flex>
              <v-list class="transparent">
                <v-list-item link to="/organization">
                  <v-list-item-icon>
                    <v-icon>mdi-account-outline</v-icon>
                    
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>General</v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn icon>
                      <v-icon style="margin-right: -12px" color="grey lighten-1"
                        >keyboard_arrow_right</v-icon
                      >
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item link to="/change-password">
                  <v-list-item-icon>
                    <!-- <v-icon>lock</v-icon> -->
                    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#5f6368"><g><rect fill="none" height="24" width="24" x="0"/></g><g><g><g><path d="M12,17c1.1,0,2-0.9,2-2s-0.9-2-2-2s-2,0.9-2,2S10.9,17,12,17z M18,8h-1V6c0-2.76-2.24-5-5-5S7,3.24,7,6v2H6 c-1.1,0-2,0.9-2,2v10c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V10C20,8.9,19.1,8,18,8z M8.9,6c0-1.71,1.39-3.1,3.1-3.1 s3.1,1.39,3.1,3.1v2H8.9V6z M18,20H6V10h12V20z"/></g></g></g></svg>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Change Password</v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn icon>
                      <v-icon style="margin-right: -12px" color="grey lighten-1"
                        >keyboard_arrow_right</v-icon
                      >
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
                <!-- 
                <v-list-item link to="/products">
                  <v-list-item-icon>
                    <v-icon>account_balance</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Products</v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn icon>
                      <v-icon style="margin-right: -12px" color="grey lighten-1"
                        >keyboard_arrow_right</v-icon
                      >
                    </v-btn>
                  </v-list-item-action>
                </v-list-item> -->

                <v-list-item link to="/default-dialer">
                  <v-list-item-icon>
                    <!-- <v-icon>mdi-phone-dial</v-icon> -->
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#5f6368"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 19c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zM6 1c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12-8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm-6 8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/></svg>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Default Dialer</v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn icon>
                      <v-icon style="margin-right: -12px" color="grey lighten-1"
                        >keyboard_arrow_right</v-icon
                      >
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>

                <v-list-item link to="/dispo">
                  <v-list-item-icon>
                    <!-- <v-icon>label</v-icon> -->
                    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#5f6368"><rect fill="none" height="24" width="24"/><path d="M18.6,19.5H21v2h-6v-6h2v2.73c1.83-1.47,3-3.71,3-6.23c0-4.07-3.06-7.44-7-7.93V2.05c5.05,0.5,9,4.76,9,9.95 C22,14.99,20.68,17.67,18.6,19.5z M4,12c0-2.52,1.17-4.77,3-6.23V8.5h2v-6H3v2h2.4C3.32,6.33,2,9.01,2,12c0,5.19,3.95,9.45,9,9.95 v-2.02C7.06,19.44,4,16.07,4,12z M16.24,8.11l-5.66,5.66l-2.83-2.83l-1.41,1.41l4.24,4.24l7.07-7.07L16.24,8.11z"/></svg>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title
                      >Custom Status / Disposition</v-list-item-title
                    >
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn icon>
                      <v-icon style="margin-right: -12px" color="grey lighten-1"
                        >keyboard_arrow_right</v-icon
                      >
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>

                <v-list-item link to="/fields">
                  <v-list-item-icon>
                    <!-- <v-icon>edit_attributes</v-icon> -->
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#5f6368"><path d="M0 0h24v24H0z" fill="none"/><path d="M4 9h16v2H4V9zm0 4h10v2H4v-2z"/></svg>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Custom Fields</v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn icon>
                      <v-icon style="margin-right: -12px" color="grey lighten-1"
                        >keyboard_arrow_right</v-icon
                      >
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>

                <v-list-item link to="/templates">
                  <v-list-item-icon>
                    <!-- <v-icon>message</v-icon> -->
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#5f6368"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H5.17l-.59.59-.58.58V4h16v12zM6 12h2v2H6zm0-3h2v2H6zm0-3h2v2H6zm4 6h5v2h-5zm0-3h8v2h-8zm0-3h8v2h-8z"/></svg>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Message Templates</v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn icon>
                      <v-icon style="margin-right: -12px" color="grey lighten-1"
                        >keyboard_arrow_right</v-icon
                      >
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>

                <v-list-item link to="/storage">
                  <v-list-item-icon>
                    <!-- <v-icon>mdi-database</v-icon> -->
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#5f6368"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM19 18H6c-2.21 0-4-1.79-4-4 0-2.05 1.53-3.76 3.56-3.97l1.07-.11.5-.95C8.08 7.14 9.94 6 12 6c2.62 0 4.88 1.86 5.39 4.43l.3 1.5 1.53.11c1.56.1 2.78 1.41 2.78 2.96 0 1.65-1.35 3-3 3zm-9-3.82l-2.09-2.09L6.5 13.5 10 17l6.01-6.01-1.41-1.41z"/></svg>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Storage</v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn icon>
                      <v-icon style="margin-right: -12px" color="grey lighten-1"
                        >keyboard_arrow_right</v-icon
                      >
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>

                <!-- <v-list-item
                  link
                  to="/form"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-form-select</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Forms</v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn icon>
                      <v-icon
                        style="margin-right: -12px"
                        color="grey lighten-1"
                      >keyboard_arrow_right</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item> -->
              </v-list>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    companyDetails() {
      return this.$store.getters.companyDetails;
    },

    computeLogo() {
      return require("../assets/logo.png");
    },
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped>
.v-card {
  border-radius: 2px !important;
  margin: 5px;
  padding: 1.2rem;
}
</style>
